import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/ButtonStyle.css'; 
function Home() {
  return (
    <div>
    <h2>Home</h2>
    <p>Bienvenido a la página de inicio.</p>
    <Link to="/about" className="nav-link">Ir a la página Acerca de</Link>
    <br />
    <Link to="/contact" className="nav-link">Ir a la página de Contacto</Link>
  </div>
  );
}

export default Home;