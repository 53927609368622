// Contact.js
import React from 'react';
import { Link } from 'react-router-dom';

function Contact() {
  return (
    <div>
      <h2>Contact</h2>
      <p>Información de contacto.</p>
      <Link to="/" className="nav-link">Ir a la página de inicio</Link>
      <br />
      <Link to="/about" className="nav-link">Ir a la página Acerca de</Link>
    </div>
  );
}

export default Contact;